import { Component, OnInit } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from "@angular/router";

import { SEOService } from '../../../core/shared/seo.service';
import 'rxjs/add/operator/switchMap'; 
import 'rxjs/add/operator/map';
@Component({
  selector: '[app-help-video]',
  templateUrl: './help-video.component.html',
  styleUrls: ['./help-video.component.scss']
})
export class HelpVideoComponent implements OnInit {
  candidate_page_collapsed = true;
  jobs_page_collapsed = true;
  candidate_card_collapsed= true;
  invitations_collapsed=true;
  reports_collapsed=true;
  sync_desktop_collapsed=true;
  email_campaigns_collapsed=true;
  career_page_collapsed=true;
  url:any;
  name:string;
  video: any = {id: '76193VtgaOA'};
  baseUrl:string = 'https://www.youtube.com/embed/';
  url_params:any;
  constructor(private sanitizer: DomSanitizer,
        public router: Router, 
        public route: ActivatedRoute,
        private seo: SEOService
        ) {}

  ngOnInit() {
  	this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl + this.video.id+"?rel=0"); 
  	// this.update_video('76193VtgaOA') ;
    console.log(this.router.url);
    let url = this.router.url;
    // if(url.includes('listview')|| url.includes('email') || url.includes('database')){
    //   this.candidate_page_collapsed = true;
    //   this.jobs_page_collapsed = true;
    //   this.candidate_card_collapsed= false;
    //   this.invitations_collapsed=true;
    //   this.reports_collapsed=true;
    //   this.sync_desktop_collapsed=true;
    //   this.email_campaigns_collapsed=true;
    //   this.career_page_collapsed=true;
    // }
    // else if(url.includes('inbox')){
    //   this.candidate_page_collapsed = false;
    //   this.jobs_page_collapsed = true;
    //   this.candidate_card_collapsed= true;
    //   this.invitations_collapsed=true;
    //   this.reports_collapsed=true;
    //   this.sync_desktop_collapsed=true;
    //   this.email_campaigns_collapsed=true;
    //   this.career_page_collapsed=true;
    // }
    // else if(url.includes('jobs')){
    //   this.candidate_page_collapsed = true;
    //   this.jobs_page_collapsed = false;
    //   this.candidate_card_collapsed= true;
    //   this.invitations_collapsed=true;
    //   this.reports_collapsed=true;
    //   this.sync_desktop_collapsed=true;
    //   this.email_campaigns_collapsed=true;
    //   this.career_page_collapsed=true;
    // }
    // else if(url.includes('invitations')){
    //   this.candidate_page_collapsed = true;
    //   this.jobs_page_collapsed = true;
    //   this.candidate_card_collapsed= true;
    //   this.invitations_collapsed=false;
    //   this.reports_collapsed=true;
    //   this.sync_desktop_collapsed=true;
    //   this.email_campaigns_collapsed=true;
    //   this.career_page_collapsed=true;
    // }
    // else if(url.includes('reports')){
    //   this.candidate_page_collapsed = true;
    //   this.jobs_page_collapsed = true;
    //   this.candidate_card_collapsed= true;
    //   this.invitations_collapsed=true;
    //   this.reports_collapsed=false;
    //   this.sync_desktop_collapsed=true;
    //   this.email_campaigns_collapsed=true;
    //   this.career_page_collapsed=true;
    // }
    // else if(url.includes('download')){
    //   this.candidate_page_collapsed = true;
    //   this.jobs_page_collapsed = true;
    //   this.candidate_card_collapsed= true;
    //   this.invitations_collapsed=true;
    //   this.reports_collapsed=true;
    //   this.sync_desktop_collapsed=false;
    //   this.email_campaigns_collapsed=true;
    //   this.career_page_collapsed=true;
    // }
    // else if(url.includes('email-campaign')){
    //   this.candidate_page_collapsed = true;
    //   this.jobs_page_collapsed = true;
    //   this.candidate_card_collapsed= true;
    //   this.invitations_collapsed=true;
    //   this.reports_collapsed=true;
    //   this.sync_desktop_collapsed=true;
    //   this.email_campaigns_collapsed=false;
    //   this.career_page_collapsed=true;
    // }
    // else if(url.includes('career')){
    //   this.candidate_page_collapsed = true;
    //   this.jobs_page_collapsed = true;
    //   this.candidate_card_collapsed= true;
    //   this.invitations_collapsed=true;
    //   this.reports_collapsed=true;
    //   this.sync_desktop_collapsed=true;
    //   this.email_campaigns_collapsed=true;
    //   this.career_page_collapsed=false;
    // }
     if (url.includes('add_candidate'))
  {
    this.candidate_page_collapsed = false;
      this.jobs_page_collapsed = true;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
else if (url.includes('search_basics'))
  {
    this.candidate_page_collapsed = false;
      this.jobs_page_collapsed = true;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
else if (url.includes('boolean_search'))
  {
    this.candidate_page_collapsed = false;
      this.jobs_page_collapsed = true;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
else if (url.includes('transfer_to_job'))
  {
    this.candidate_page_collapsed = false;
      this.jobs_page_collapsed = true;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
else if (url.includes('download_spreadsheet'))
  {
    this.candidate_page_collapsed = false;
      this.jobs_page_collapsed = true;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
else if (url.includes('delete_candidates'))
  {
    this.candidate_page_collapsed = false;
      this.jobs_page_collapsed = true;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
else if (url.includes('search_email_campaign_stats'))
  {
    this.candidate_page_collapsed = false;
      this.jobs_page_collapsed = true;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
else if (url.includes('candidate_card_basics'))
  {
    this.candidate_page_collapsed = true;
      this.jobs_page_collapsed = true;
      this.candidate_card_collapsed= false;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
else if (url.includes('standardized_resume'))
  {
    this.candidate_page_collapsed = true;
      this.jobs_page_collapsed = true;
      this.candidate_card_collapsed= false;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
else if (url.includes('email_candidate'))
  {
    this.candidate_page_collapsed = true;
      this.jobs_page_collapsed = true;
      this.candidate_card_collapsed= false;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
else if (url.includes('resume_watermark'))
  {
    this.candidate_page_collapsed = true;
      this.jobs_page_collapsed = true;
      this.candidate_card_collapsed= false;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
else if (url.includes('add_comments'))
  {
    this.candidate_page_collapsed = true;
      this.jobs_page_collapsed = true;
      this.candidate_card_collapsed= false;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
else if (url.includes('view_download_resume'))
  {
    this.candidate_page_collapsed = true;
      this.jobs_page_collapsed = true;
      this.candidate_card_collapsed= false;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
else if (url.includes('create_job'))
  {
    this.candidate_page_collapsed = true;
      this.jobs_page_collapsed = false;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
else if (url.includes('add_resume_to_job'))
  {
    this.candidate_page_collapsed = true;
      this.jobs_page_collapsed = false;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
else if (url.includes('job_tracking'))
  {
    this.candidate_page_collapsed = true;
      this.jobs_page_collapsed = false;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
else if (url.includes('applicant_tracking'))
  {
    this.candidate_page_collapsed = true;
      this.jobs_page_collapsed = false;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
 else if (url.includes('schedule_interview'))
  {
    this.candidate_page_collapsed = true;
      this.jobs_page_collapsed = false;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
else if (url.includes('filter_candidates'))
  {
    this.candidate_page_collapsed = true;
      this.jobs_page_collapsed = false;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
else if (url.includes('candidate_actions_in_job'))
  {
    this.candidate_page_collapsed = true;
      this.jobs_page_collapsed = false;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
else if (url.includes('client_management'))
  {
    this.candidate_page_collapsed = true;
      this.jobs_page_collapsed = false;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
else if (url.includes('job_management'))
  {
    this.candidate_page_collapsed = true;
      this.jobs_page_collapsed = false;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
else if (url.includes('edit_job'))
  {
    this.candidate_page_collapsed = true;
      this.jobs_page_collapsed = false;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
else if (url.includes('mass_open_close_jobs'))
  {
    this.candidate_page_collapsed = true;
      this.jobs_page_collapsed = false;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
else if (url.includes('auto_forwarding'))
  {
    this.candidate_page_collapsed = true;
      this.jobs_page_collapsed = false;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
else if (url.includes('download_candidate_tracker'))
  {
    this.candidate_page_collapsed = true;
      this.jobs_page_collapsed = false;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
else if (url.includes('manage_invites'))
  {
    this.candidate_page_collapsed = true;
      this.jobs_page_collapsed = true;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=false;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
else if (url.includes('manage_reports'))
  {
    this.candidate_page_collapsed = true;
      this.jobs_page_collapsed = true;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=true;
      this.reports_collapsed=false;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
else if (url.includes('tobu_drive'))
  {
    this.candidate_page_collapsed = true;
      this.jobs_page_collapsed = true;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=false;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=true;
  }
else if (url.includes('create_email_campaign'))
  {
    this.candidate_page_collapsed = true;
      this.jobs_page_collapsed = true;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=false;
      this.career_page_collapsed=true;
  }
else if (url.includes('email_campaign_stats'))
  {
    this.candidate_page_collapsed = true;
      this.jobs_page_collapsed = true;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=false;
      this.career_page_collapsed=true;
  }
else if (url.includes('create_email_template'))
  {
    this.candidate_page_collapsed = true;
      this.jobs_page_collapsed = true;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=false;
      this.career_page_collapsed=true;
  }
else if (url.includes('configure_email'))
  {
    this.candidate_page_collapsed = true;
      this.jobs_page_collapsed = true;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=false;
      this.career_page_collapsed=true;
  }
else if (url.includes('activate_database'))
  {
    this.candidate_page_collapsed = true;
      this.jobs_page_collapsed = true;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=false;
      this.career_page_collapsed=true;
  }
else if (url.includes('manage_career_page'))
  {
    this.candidate_page_collapsed = true;
      this.jobs_page_collapsed = true;
      this.candidate_card_collapsed= true;
      this.invitations_collapsed=true;
      this.reports_collapsed=true;
      this.sync_desktop_collapsed=true;
      this.email_campaigns_collapsed=true;
      this.career_page_collapsed=false;
  }
    // this.url_params=this.route.snapshot.params
    this.route.params.subscribe(
      res=> {
        console.log(res);
        this.url_params=res;
        if(this.url_params != null && this.url_params != undefined){ 
          if (this.url_params['id']==='add_candidate')
            {this.update_video('Igs0POmQWy8')}
          else if (this.url_params['id']==='search_basics')
            {this.update_video('luJ1Ldb9X1w')}
          else if (this.url_params['id']==='boolean_search')
            {this.update_video('aKUsTrxM72o')}
          else if (this.url_params['id']==='transfer_to_job')
            {this.update_video('ZgGJDIkqSZE')}
          else if (this.url_params['id']==='download_spreadsheet')
            {this.update_video('lTrl7WJulFw')}
          else if (this.url_params['id']==='delete_candidates')
            {this.update_video('ap6Bo9ARm2k')}
          else if (this.url_params['id']==='search_email_campaign_stats')
            {this.update_video('AN1YT2r1-N8')}
          else if (this.url_params['id']==='candidate_card_basics')
            {this.update_video('fZCHayRob6U')}
          else if (this.url_params['id']==='standardized_resume')
            {this.update_video('6eE5-Hz3YAA')}
          else if (this.url_params['id']==='email_candidate')
            {this.update_video('76193VtgaOA')}
          else if (this.url_params['id']==='resume_watermark')
            {this.update_video('OuR4IPvo1LI')}
          else if (this.url_params['id']==='add_comments')
            {this.update_video('1pB1RQTVGmM')}
          else if (this.url_params['id']==='view_download_resume')
            {this.update_video('ATRdibSWYkU')}
          else if (this.url_params['id']==='create_job')
            {this.update_video('rmxF5vA4Ovg')}
          else if (this.url_params['id']==='add_resume_to_job')
            {this.update_video('d1fn8yYa4J4')}
          else if (this.url_params['id']==='job_tracking')
            {this.update_video('W21-xEEjzQY')}
          else if (this.url_params['id']==='applicant_tracking')
            {this.update_video('wOeWotJ1_yM')}
           else if (this.url_params['id']==='schedule_interview')
            {this.update_video('5vIYHRBU-YM')}
          else if (this.url_params['id']==='filter_candidates')
            {this.update_video('aaZcAiF8JxA')}
          else if (this.url_params['id']==='candidate_actions_in_job')
            {this.update_video('vVtJE18n7EQ')}
          else if (this.url_params['id']==='client_management')
            {this.update_video('iIGcuV-Drlg')}
          else if (this.url_params['id']==='job_management')
            {this.update_video('vI9Dw-E3Oys')}
          else if (this.url_params['id']==='edit_job')
            {this.update_video('aBajp6EnPXE')}
          else if (this.url_params['id']==='mass_open_close_jobs')
            {this.update_video('TN6IeK2VJt8')}
          else if (this.url_params['id']==='auto_forwarding')
            {this.update_video('Ih2yWzSR9R0')}
          else if (this.url_params['id']==='download_candidate_tracker')
            {this.update_video('MbPN7OJhqVU')}
          else if (this.url_params['id']==='manage_invites')
            {this.update_video('_jZZcG8gIGM')}
          else if (this.url_params['id']==='manage_reports')
            {this.update_video('2KFK-5OOmDU')}
          else if (this.url_params['id']==='tobu_drive')
            {this.update_video('vCnWoE6qrOs')}
          else if (this.url_params['id']==='create_email_campaign')
            {this.update_video('Er3M_glhJjI')}
          else if (this.url_params['id']==='email_campaign_stats')
            {this.update_video('2WH8JgN_-Bw')}
          else if (this.url_params['id']==='create_email_template')
            {this.update_video('2X3wNkW-8AM')}
          else if (this.url_params['id']==='configure_email')
            {this.update_video('ZqhO_q0VLwM')}
          else if (this.url_params['id']==='activate_database')
            {this.update_video('g4MJkx0Dbzs')}
          else if (this.url_params['id']==='manage_career_page')
            {this.update_video('ZYyoCdLUR_E')}
            
          // return this.authService.post('/api/update_smart_filter', params);
        
      }
      },
        err=> console.log(err))
    
  	this.route.queryParams.map(queryparams=> {
      console.log(queryparams)
      // this.query_params_page = queryparams
      return queryparams;
     })
      // .switchMap(params => {
      //   // this.modal.load_modal_by_name('spinner', {});
      //   if(params != null && params != undefined){ 
      //   	if (params['title']==='tobu_drive')
      //     		{this.update_video('BxvEwLZ77jw')}
      //     	else if (params['title']==='search')
      //     		{this.update_video('NO3n6AIoeSA')}
      //     	else if (params['title']==='transfer_to_job')
      //     		{this.update_video('76193VtgaOA')}
      //     // return this.authService.post('/api/update_smart_filter', params);
      //   }
      //   else{
      //     return Observable.empty();
      //   }
      // })
      .subscribe(
      	params => {
      	if(params != null && params != undefined){ 
        	if (params['title']==='add_candidate')
          		{this.update_video('Igs0POmQWy8')}
          	else if (params['title']==='search_basics')
          		{this.update_video('luJ1Ldb9X1w')}
          	else if (params['title']==='boolean_search')
          		{this.update_video('aKUsTrxM72o')}
            else if (params['title']==='transfer_to_job')
              {this.update_video('ZgGJDIkqSZE')}
            else if (params['title']==='download_spreadsheet')
              {this.update_video('lTrl7WJulFw')}
            else if (params['title']==='delete_candidates')
              {this.update_video('ap6Bo9ARm2k')}
            else if (params['title']==='search_email_campaign_stats')
              {this.update_video('AN1YT2r1-N8')}
            else if (params['title']==='candidate_card_basics')
              {this.update_video('fZCHayRob6U')}
            else if (params['title']==='standardized_resume')
              {this.update_video('6eE5-Hz3YAA')}
            else if (params['title']==='email_candidate')
              {this.update_video('76193VtgaOA')}
            else if (params['title']==='resume_watermark')
              {this.update_video('OuR4IPvo1LI')}
            else if (params['title']==='add_comments')
              {this.update_video('1pB1RQTVGmM')}
            else if (params['title']==='view_download_resume')
              {this.update_video('ATRdibSWYkU')}
            else if (params['title']==='create_job')
              {this.update_video('rmxF5vA4Ovg')}
            else if (params['title']==='add_resume_to_job')
              {this.update_video('d1fn8yYa4J4')}
            else if (params['title']==='job_tracking')
              {this.update_video('W21-xEEjzQY')}
            else if (params['title']==='applicant_tracking')
              {this.update_video('wOeWotJ1_yM')}
             else if (params['title']==='schedule_interview')
              {this.update_video('5vIYHRBU-YM')}
            else if (params['title']==='filter_candidates')
              {this.update_video('aaZcAiF8JxA')}
            else if (params['title']==='candidate_actions_in_job')
              {this.update_video('vVtJE18n7EQ')}
            else if (params['title']==='client_management')
              {this.update_video('iIGcuV-Drlg')}
            else if (params['title']==='job_management')
              {this.update_video('vI9Dw-E3Oys')}
            else if (params['title']==='edit_job')
              {this.update_video('aBajp6EnPXE')}
            else if (params['title']==='mass_open_close_jobs')
              {this.update_video('TN6IeK2VJt8')}
            else if (params['title']==='auto_forwarding')
              {this.update_video('Ih2yWzSR9R0')}
            else if (params['title']==='download_candidate_tracker')
              {this.update_video('MbPN7OJhqVU')}
            else if (params['title']==='manage_invites')
              {this.update_video('_jZZcG8gIGM')}
            else if (params['title']==='manage_reports')
              {this.update_video('2KFK-5OOmDU')}
            else if (params['title']==='tobu_drive')
              {this.update_video('BxvEwLZ77jw')}
            else if (params['title']==='create_email_campaign')
              {this.update_video('Er3M_glhJjI')
                this.set_page_title('Tobu - Create Email Campaign Tutorial', 'Tobu - Create Email Campaign Tutorial')
              }
            else if (params['title']==='email_campaign_stats')
              {this.update_video('2WH8JgN_-Bw')
                this.set_page_title('Tobu - Email Campaign Stats Tutorial', 'Tobu - Email Campaign Stats Tutorial')
              }
            else if (params['title']==='create_email_template')
              {this.update_video('2X3wNkW-8AM')
                this.set_page_title('Tobu - Create Email Template Tutorial', 'Tobu - Create Email Template Tutorial')
              }
            else if (params['title']==='configure_email')
              {this.update_video('ZqhO_q0VLwM')
                this.set_page_title('Tobu - Configure Email Tutorial', 'Tobu - Configure Email Tutorial')
              }
            else if (params['title']==='activate_database')
              {this.update_video('g4MJkx0Dbzs');
                this.set_page_title('Tobu - Activate Database Tutorial', 'Tobu - Activate Database Tutorial')
              }
            else if (params['title']==='manage_career_page')
              {this.update_video('ZYyoCdLUR_E');
                this.set_page_title('Tobu - Career Page Video Tutorial', 'Tobu - Career Page Video Tutorial')
              }
            
          // return this.authService.post('/api/update_smart_filter', params);
        }
    	}
      )
  }
  	
  
  update_video(_id){console.log('updating link')
  	this.video.id = _id
  	this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl + this.video.id);  
  }
  set_page_title(seo_title, seo_description){
    this.seo.updateTitle(seo_title);
      this.seo.updateDescription(seo_description)
      this.seo.updateOgUrl(this.router.url);
  }

}
