import { Component, OnDestroy, ViewEncapsulation} from '@angular/core';
import { Params, Router, ActivatedRoute,NavigationEnd } from "@angular/router";
import { AuthenticationService} from '../../../../core/authentication/authentication.service'
import { Subscription }   from 'rxjs';
import {NavbarService} from '../../../../layout/header/navbar.service';
import {ModalsService} from '../../../../layout/modals/modals.service'
import { SharedService } from '../../../../core/shared/shared.service';
import { CandidateService } from '../candidate.service';
import { filter } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {saveAs} from "file-saver";


@Component({
  selector: '[app-analytics-full]',
  templateUrl: './analytics-full.component.html',
  styleUrls: ['./analytics-full.component.scss']
})
export class AnalyticsFullComponent implements OnDestroy {

  plo: any ;
  rcar: any;
  uar: any;
  loading = true
  analytics: any;
  generated_params ={};

  subscription: Subscription;
  sub1: Subscription;
  sub2: Subscription;
  uploaded_resume_id: string;
  is_resume_view_default = false;
  file_name =''
  keywords=''
  constructor(
    private authService: AuthenticationService, 
    private router: Router, 
    private route: ActivatedRoute, 
    private navbar: NavbarService,
    private shared: SharedService,
    private candidate: CandidateService,
    private modal: ModalsService,
    private sanitizer: DomSanitizer
    ) { }

  ngOnInit() {
    // console.log('loading analytics comp')
    this.get_restrictions()
    this.get_plo();
    // this.modal.load_modal_by_name('candidate', {});
  }

  ngOnDestroy(){
    console.log('destroying component')
    this.subscription.unsubscribe;
    this.sub1.unsubscribe;
    this.sub2.unsubscribe;
    // this.modal.hide_modal_by_name('candidate');
  }

  get_plo(){
    // var url_split = this.router.url.split('/');
    // var candidate_id = url_split[url_split.length-2];
    // console.log(candidate_id);
    // this.load_analytics();
    // this.sub1 = this.router.events.pipe(
    // filter(event => event instanceof NavigationEnd)  
    // ).subscribe((event: NavigationEnd) => {
    //   this.load_analytics();
    //   console.log(event.url);
    // });    
    // this.sub1 = this.route.queryParams.subscribe(
    //   res=> {
    //     this.load_analytics();
    //   }
    // )
    this.sub1 = this.route.queryParams.subscribe(
      res=> {
        this.loading=true
        console.log(this.route.snapshot.queryParams['open_result'])
        if(this.route.snapshot.queryParams['open_result']===undefined){this.sub1.unsubscribe()}
        else{this.load_analytics();}
      }
    )  
  }

  load_analytics(){
    this.sub2=this.fetch_analytics()
      .subscribe(
        res => {
        if(res['notice']==='Access Denied')
          {
            this.router.navigate(['/candidate_inbox'])
          }
          this.plo =  res
          this.analytics = this.sanitizer.bypassSecurityTrustHtml(res['analytics']);
          this.is_resume_view_default = res['is_resume_view_default']
          this.uploaded_resume_id=res['uploaded_resume_id']
          this.file_name = res['file_name'];
          this.keywords = res['keyword_counts']
          // this.analytics=this.sanitizer.bypassSecurityTrustHtml("<body><meta charset=\"UTF-8\"><title>Sample Resume</title><p>John Doe</p><p>Software Engineer</p><p><strong>Email: </strong><a href=\"mailto:john.doe@example.com\">john.doe@example.com</a></p><p><a href=\"http://linkedin.com/in/johndoe\">LinkedIn</a></p><p>Experienced in developing scalable web applications and APIs.</p></body>")
          console.log(this.analytics)
          this.loading = false
          console.log(res);
        }, error => {
          // console.log(error);
        }
      )
  }
  
  fetch_analytics(){
    this.generated_params = this.candidate.get_params_from_url(this.router.url);
    console.log(this.generated_params)
    console.log(this.router.url.includes('inbox'))
    if(this.router.url.includes('inbox')){
      return this.authService.post('/api/get_resume_analytics_for_resume', this.generated_params)
    }
    else
    {
      return this.authService.post('/api/get_resume_analytics_for_resume_report', this.generated_params)
    }
  }
  get_restrictions(){
    this.subscription= this.shared.current_restrictions$.subscribe(
        res => {
          this.rcar = res["rcar"];
          this.uar = res["uar"]
        }
    );
  }
  default_analytics_view(){
    this.authService.post('/api/default_analytics_view', {}).subscribe(
      res=> {
        this.navbar.set_notification('Success','Notice', res['notice']);
        this.is_resume_view_default = !this.is_resume_view_default
      })
  }
  download_resume(_file_name){
    // console.log('downloading');
    console.log(this.uploaded_resume_id)
    this.authService.downloadFile('/api/download_resume', {id: this.uploaded_resume_id}).subscribe(blob => {
            console.log(blob)
            saveAs(blob, _file_name);
        }, error => {
              // console.log(error);
        }
    )
  }

}
