import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/authentication.service'
import { ActivatedRoute, Router, Params} from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators} from'@angular/forms';
import { SharedService } from '../../../core/shared/shared.service';
import { NavbarService } from '../../../layout/header/navbar.service'
import { Subscription, Observable } from 'rxjs';
import { ModalsService } from '../../../layout/modals/modals.service';

@Component({
  selector: '[app-admin-update-pricing-plan]',
  templateUrl: './admin-update-pricing-plan.component.html',
  styleUrls: ['./admin-update-pricing-plan.component.scss']
})
export class AdminUpdatePricingPlanComponent implements OnInit, OnDestroy {

  
  subscription: Subscription;
  subscription2: Subscription;
  subscription3: Subscription;
  subscription4: Subscription;
  // hiddenEditForm = true;
  clicked_save =false;
  updatePricingPlanForm = new FormGroup({
    pricing_plan: new FormControl(),
    expiry_date: new FormControl(),
    stop_date_for_historic_sync: new FormControl(),
    user_type: new FormControl(),
    resume_limit: new FormControl(),
    daily_email_limit: new FormControl(),
    email_account_limit: new FormControl(),
    account_status: new FormControl(),
    tobu_test_search: new FormControl()
  });
  display_email_status = false;
  company_id:any;
  user_id:any;
  email_linked:any;
  sample_historic_sync_complete:any;
  historic_sync_complete:any;
  bsValue = new Date();
  maxDate = new Date();
  bsConfig = {
      containerClass: 'theme-angle'
  }
  pricing_plans = ['Standard INR', 'Premium INR']
  user_types = ['Free', 'Paid', 'Trial']
  tobu_test_options = [ 'false', 'true']
  email=''
  company_name=''
  constructor(
  	private authService: AuthenticationService,
  	private route: ActivatedRoute,
  	private router: Router,
  	private shared: SharedService,
  	private navbar: NavbarService,
  	private modal: ModalsService,
  	) { }

  // ngOnDestroy(){
  // 	this.subscription.unsubscribe;
  // }

  ngOnInit(){
  	this.get_plo();

  }
  ngOnDestroy(){
    this.subscription.unsubscribe;
    this.subscription2.unsubscribe;
    // this.subscription3.unsubscribe;
    this.subscription4.unsubscribe;

  }
  get_plo(){
  	this.subscription4 = this.authService.get('/api/get_all_pricing_plans').subscribe(
  		res=>{
        console.log(res)
  			this.pricing_plans = res['pricing_plans'];
  		}, 
  		err => console.log(err))
  	this.subscription2=this.navbar.admin_update_pricing_plan$.subscribe(
  		res=> {
  			console.log(res)
  			this.company_id=res['company_id']
        this.user_id=res['user_id']
        this.get_admin_company_details(this.user_id)
  		});
  }
  
  submitUpdatePricingPlanForm($ev, value: any) {
    console.log(value);
    $ev.preventDefault();
    for (let c in this.updatePricingPlanForm.controls) {
        this.updatePricingPlanForm.controls[c].markAsTouched();
    }

    if (this.updatePricingPlanForm.valid) {
      this.clicked_save =true;
        console.log(value)
        console.log(this.company_id)
        this.authService.post(
          '/api/admin_update_pricing_plan', this.shared.merge_options(value, {'company_id': this.company_id, user_id: this.user_id})
          ).subscribe(
          res => {
              console.log(res);
              this.clicked_save =false;
              this.navbar.set_notification("Success", "Success", res['notice']);
              // setTimeout(function(){ location.reload(); }, 3000);
              this.shared.refresh(this.router.url);
          }, error => {
              // console.log(error);
          }
        )
    }
    else{
       this.navbar.set_notification('Warning', 'Please complete form', 'Please ensure all fields are filled')
    }
  }
  get_admin_company_details(_user_id){
    this.subscription=this.authService.get('/api/get_admin_company_details', {id:_user_id}).subscribe(
      res=> {
        console.log(res);
        this.company_name=res['company_name']
        this.email=res['user_email']
        // this.email_linked = res['email_linked']
        // this.sample_historic_sync_complete = !res['did_sample_historic_email_sync_fail']
        // this.historic_sync_complete = !res['did_historic_email_sync_fail']
        this.updatePricingPlanForm.patchValue({
          pricing_plan: res['pricing_plan'],
          expiry_date: new Date(res['expiry_date']),
          stop_date_for_historic_sync: new Date(res['stop_date_for_historic_sync']),
          resume_limit: res['resume_limit'],
          daily_email_limit: res['daily_email_limit'],
          email_account_limit: res['email_account_limit'],
          user_type: res['status'],
          account_status: res['is_disabled'] ? "Disabled User" : "Active Member"
          })
      },
      err=> console.log(err))
  }
  get_admin_user_email_status(){
    this.subscription3 = this.authService.get('/api/get_admin_user_email_status', {id:this.user_id}).subscribe(
      res=> {
        console.log(res);
        this.email_linked = res['email_linked']
        this.sample_historic_sync_complete = !res['did_sample_historic_email_sync_fail']
        this.historic_sync_complete = !res['did_historic_email_sync_fail']
      },
      err=> console.log(err))
  }
  restart_sample_historic_sync(){
    this.authService.post('/api/restart_sample_historic_sync', {id:this.user_id}).subscribe(
      res=> {
        console.log(res);
        this.navbar.set_notification("Success", "Success", res['notice']);
      },
      err=> console.log(err))
  }
  restart_historic_sync(){
    this.authService.post('/api/restart_historic_sync', {id:this.user_id}).subscribe(
      res=> {
        console.log(res);
        this.navbar.set_notification("Success", "Success", res['notice']);
      },
      err=> console.log(err))
  }
  show_email_status(){
    this.get_admin_user_email_status();
    this.display_email_status = !this.display_email_status
  }
}
